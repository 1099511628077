<script>
/**
 * @typedef { import('vue').Ref } Ref
 */

/**
 * Represents the profile of a job applicant, including personal, professional, and application-specific details.
 *
 * @typedef {?Object} ApplicantProfile
 * @property {number} id - Unique identifier for the applicant.
 * @property {string} full_name - Full name of the applicant.
 * @property {?number} main_application_id - The ID of the main application, if applicable.
 * @property {string} first_name - Applicant's first name.
 * @property {string} last_name - Applicant's last name.
 * @property {string} email - Email address of the applicant.
 * @property {?string} phone - Phone number of the applicant, if available.
 * @property {number} average_rating - The average rating of the applicant.
 * @property {string} city - City of residence.
 * @property {string} timezone - Timezone of the applicant's location.
 * @property {number} relocate - Indicates if the applicant is willing to relocate (0 for no, 1 for yes).
 * @property {string} competency - Area of competency, e.g., 'Frontend'.
 * @property {string} country - Country of residence.
 * @property {string} country_code - Two-letter country code.
 * @property {number} commercial_exp_months - Total months of commercial experience.
 * @property {number} freelance_exp_months - Total months of freelance experience.
 * @property {string} commercial_experience - Human-readable commercial experience duration.
 * @property {string} freelance_experience - Human-readable freelance experience duration.
 * @property {number} english_level - English proficiency level of the applicant.
 * @property {?number} english_cefr_score - CEFR score for English proficiency, if available.
 * @property {number} monthly_rate - Expected monthly rate in EUR.
 * @property {number} hourly_rate - Expected hourly rate in EUR.
 * @property {?string|number} monthly_rate_updated - Last update time of monthly rate.
 * @property {?string|number} hourly_rate_updated - Last update time of hourly rate.
 * @property {string} commitment_type - Types of commitment the applicant is open to, e.g., Full-time, Part-time.
 * @property {number} hiring_probability - Probability of hiring the applicant.
 * @property {number} profile_probability - Probability of the profile being a good fit.
 * @property {number} skill_ratio - Skill ratio of the applicant.
 * @property {string} applied_at - Date and time when the applicant applied.
 * @property {?string} project_interested - Project(s) the applicant is interested in, if any.
 * @property {?number} team_size - Preferred team size, if specified.
 * @property {number} want_to_fulltime - Indicates if the applicant wants a full-time position (0 for no, 1 for yes).
 * @property {string} first_start_fulltime - Availability date for starting full-time.
 * @property {string} first_start_parttime - Availability date for starting part-time.
 * @property {string} source - Source of the application, e.g., 'careers'.
 * @property {Object} traffic_source - Traffic source details.
 * @property {?number} referrer_id - Referrer ID, if applicable.
 * @property {?string} referrer_email - Referrer email, if applicable.
 * @property {Object} social_links - Social media links of the applicant.
 * @property {Object} stage - Current stage of the application process.
 * @property {Object[]} application_skills - Array of skills with details related to the application.
 * @property {string} last_activity - Date and time of the last activity by the applicant.
 * @property {boolean} is_rejected - Indicates if the applicant has been rejected.
 * @property {?string} rejected_at - Date and time when the applicant was rejected, if applicable.
 * @property {string[]} rejection_reasons - Reasons for rejection, if any.
 * @property {?string} rejection_type - Type of rejection, if applicable.
 * @property {number} is_hired - Indicates if the applicant has been hired (0 for no, 1 for yes).
 * @property {?string} hired_at - Hiring date, if applicable.
 * @property {string} currency - Currency for rates, e.g., 'EUR'.
 * @property {number} currency_rate - Conversion rate for the currency.
 * @property {boolean} fast_tracked - Indicates if the applicant is in the fast-track process.
 * @property {string} fast_track_choice - Fast track process choice.
 * @property {boolean} interview_is_filled - Indicates if the interview slot has been filled.
 * @property {Object} supply_demand_ratio - Supply and demand ratio details.
 * @property {boolean} has_not_scheduled_meeting - Indicates if the applicant has not scheduled a meeting yet.
 * @property {?number} cv_matching_score - CV matching score, if calculated.
 * @property {number} priority - Priority level of the application.
 * @property {string} urgency - Urgency of the application, e.g., 'critical'.
 * @property {?string} desired_test_date - Desired date for taking a test, if specified.
 * @property {?string} auto_transition - Auto transition status, if applicable.
 * @property {?Object} applicant - Applicant details, if available.
 * @property {Object} assignees - Details of individuals assigned to the application.
 * @property {?Object} rejected_by - Details of the individual who rejected the application, if applicable.
 * @property {Object[]} attachments - Array of attachments related to the application.
 * @property {Object[]} recordings - Array of recordings related to the application.
 * @property {Object} interviewers - Details of interviewers assigned to the application.
 * @property {Object} duplications - Details of any duplicate applications.
 * @property {?number} grade - Grade of the applicant, if applicable.
 * @property {Object} metric_distribution - Distribution of various metrics related to the application.
 */

export default {
  name: 'ApplicationForm',
};
</script>
<script setup>
import { useStore } from 'vuex';
import { useForm } from 'vee-validate';
import { useConfirmDialog } from '@vueuse/core';
import { computed, ref, toRefs } from 'vue';
import { PDialog, PSeparator } from '@/components/ProxifyUI';
import { BaseAlert, BaseConfirmationCard } from '@/components/Base';
import ApplicationFormSectionSource from '@/components/Elements/Application/ApplicationFormSectionSource.vue';
import ApplicationFormSectionLinks from '@/components/Elements/Application/ApplicationFormSectionLinks.vue';
import ApplicationFormSectionAdditionalInformation from '@/components/Elements/Application/ApplicationFormSectionAdditionalInformation.vue';
import ApplicationFormSectionExperience from '@/components/Elements/Application/ApplicationFormSectionExperience.vue';
import ApplicationFormSectionPersonalInformation from '@/components/Elements/Application/ApplicationFormSectionPersonalInformation.vue';

const props = defineProps({
  initialData: {
    /** @type {{ new (): ApplicantProfile }} */
    type: Object,
    required: false,
    default() {
      return {};
    },
  },
  onSubmit: {
    type: Function,
    required: true,
  },
  uploadProgress: {
    type: Number,
    required: false,
    default() {
      return 100;
    },
  },
  title: {
    type: String,
    default() {
      return 'Add new applicant';
    },
  },
  previousStageName: {
    type: String,
    required: false,
    default() {
      return '';
    },
  },
  nextStageName: {
    type: String,
    required: false,
    default() {
      return '';
    },
  },
});
const emit = defineEmits(['cancel']);
const store = useStore();
const { reveal, isRevealed, cancel, onCancel, confirm } = useConfirmDialog();

/** @type {Ref<boolean>} */
const isLoading = ref(false);
/** @type {Ref<ApplicantProfile>} */
const form = ref({
  attachments: {},
});
/** @type {Ref<boolean>} */
const expanded = ref(false);

const { initialData, previousStageName, nextStageName } = toRefs(props);

const isInSourcingStage = computed(() => initialData.value.stage?.is_sourcing);
const isEditing = computed(() => Boolean(initialData.value.id));
const allowNameOnlySkills = computed(() => {
  return (
    (!isEditing.value || isInSourcingStage.value) &&
    form.value.source === 'sourced' &&
    (!nextStageName.value || nextStageName.value.includes('Sourcing'))
  );
});

const requiredFields = computed(() => {
  const alwaysRequired = [
    'First name',
    'Last name',
    'Country',
    'Source',
    'Sourcer',
    'Linkedin',
    'CV File',
    'Referral e-mail',
  ];
  const requiredForVetting = [
    'E-mail',
    'Main skills',
    'Commercial experience',
    'English level',
    'Competency',
    'Hourly rate',
  ];
  const requiredForNonSourced = [
    'E-mail',
    'Main skills',
    'Commercial experience',
    'English level',
    'Competency',
  ];
  const isTransitioning = Boolean(
    previousStageName.value && nextStageName.value
  );
  return form.value.source
    ? [
        ...alwaysRequired,
        ...(form.value.source !== 'sourced' ? requiredForNonSourced : []),
        ...(form.value.source === 'sourced' &&
        isEditing.value &&
        (!isInSourcingStage.value || isTransitioning)
          ? requiredForVetting
          : []),
      ]
    : ['Source'];
});

const schema = computed(() => {
  const validSkillCondition =
    (isEditing.value && !isInSourcingStage.value) ||
    form.value.source !== 'sourced' ||
    (Boolean(nextStageName.value) && !nextStageName.value.includes('Sourcing'));
  return isRevealed.value
    ? requiredFields.value.reduce(
        (accumulator, name) => {
          return {
            [name]: {
              required: !(
                (name === 'CV File' && form.value?.linkedin_link) ||
                (name === 'Linkedin' && form.value?.attachments?.cv_file) ||
                (name === 'Sourcer' && form.value?.source !== 'sourced') ||
                (name === 'Referral e-mail' &&
                  form.value?.source !== 'referred')
              ),
            },
            ...accumulator,
          };
        },
        {
          'Main skills': {
            required: requiredFields.value.includes('Main skills'),
            validSkill: validSkillCondition,
          },
          'Secondary Skills': {
            required: requiredFields.value.includes('Secondary Skills'),
            validSkill: validSkillCondition,
          },
        }
      )
    : {};
});

const { handleSubmit } = useForm({
  validationSchema: schema,
});
const save = async () => {
  const { onSubmit } = props;
  if (isLoading.value) return;
  try {
    isLoading.value = true;
    await onSubmit(form.value);
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
    confirm();
  }
};

const handleInvalidSubmit = ({ errors }) => {
  store.commit('ui/addSnackbarMessage', {
    title: 'You have to fill all the required fields',
    type: 'error',
  });
  const invalidFields = Object.keys(errors);
  const firstInvalidQuestionElement = document.querySelector(
    `[data-name='${invalidFields[0]}']`
  );
  if (firstInvalidQuestionElement) {
    firstInvalidQuestionElement.scrollIntoView();
  }
};

const submit = handleSubmit(save, handleInvalidSubmit);

onCancel(() => {
  form.value = {
    attachments: {},
  };
  emit('cancel');
});

defineExpose({
  reveal,
  submit,
});
</script>

<template>
  <PDialog
    v-model="isRevealed"
    persistent
    position="standard"
  >
    <BaseConfirmationCard
      :cancel-button-text="null"
      confirm-button-text="Submit"
      :is-loading="isLoading"
      :on-confirm="submit"
      :on-close="cancel"
      class="!max-w-[640px] w-full"
      width="640px"
      paddingless
      footer-border
    >
      <template #title>{{ title }}</template>
      <form
        class="px-6 pb-6 flex flex-col gap-4"
        @submit="submit"
      >
        <BaseAlert
          v-if="previousStageName && nextStageName"
          color="warning"
          hide-close-button
          vertical
        >
          <div class="text-body-sm">
            Fill required applicant information to be able to change the stage:
          </div>
          <div class="text-body-md font-medium">
            {{ previousStageName }} → {{ nextStageName }}
          </div>
        </BaseAlert>
        <ApplicationFormSectionSource
          :referrer-email="initialData.referrer_email"
          :source="initialData.source"
          :sourcer-id="initialData.assignees?.sourcer?.id"
          :traffic-source="initialData?.traffic_source"
          :required-fields="requiredFields"
          @update:referrer-email="(value) => (form.referrer_email = value)"
          @update:source="(value) => (form.source = value)"
          @update:sourcer-id="(value) => (form.sourcer_id = value)"
        />
        <PSeparator class="mt-2" />
        <ApplicationFormSectionPersonalInformation
          :city="initialData.city"
          :country="initialData.country"
          :email="initialData.email"
          :first-name="initialData.first_name"
          :last-name="initialData.last_name"
          :phone="initialData.phone"
          :relocate="Boolean(initialData.relocate)"
          :required-fields="requiredFields"
          @update:city="(value) => (form.city = value)"
          @update:country="(value) => (form.country = value)"
          @update:email="(value) => (form.email = value)"
          @update:first-name="(value) => (form.first_name = value)"
          @update:last-name="(value) => (form.last_name = value)"
          @update:phone="(value) => (form.phone = value)"
          @update:relocate="(value) => (form.relocate = value)"
        />
        <PSeparator class="mb-2" />
        <ApplicationFormSectionExperience
          :commercial-experience-months="initialData.commercial_exp_months"
          :competency="initialData.competency"
          :english-level="initialData.english_level"
          :freelance-experience-months="initialData.freelance_exp_months"
          :application-skills="initialData.application_skills"
          :required-fields="requiredFields"
          :allow-name-only-skills="allowNameOnlySkills"
          :source="form.source"
          @update:commercial-experience-months="
            (value) => (form.commercial_exp_months = value)
          "
          @update:competency="(value) => (form.competency = value)"
          @update:english-level="(value) => (form.english_level = value)"
          @update:freelance-experience-months="
            (value) => (form.freelance_exp_months = value)
          "
          @update:application-skills="(value) => (form.skills = value)"
        />
        <PSeparator class="mb-2" />
        <ApplicationFormSectionAdditionalInformation
          :commitment-type="initialData.commitment_type"
          :currency="initialData.currency"
          :hourly-rate="initialData.hourly_rate"
          :monthly-rate="initialData.monthly_rate"
          :project-interested="initialData.project_interested"
          :required-fields="requiredFields"
          :team-size="initialData.team_size"
          :want-to-full-time="Boolean(initialData.want_to_fulltime)"
          @update:commitment-type="(value) => (form.work_type = value)"
          @update:currency="(value) => (form.currency = value)"
          @update:hourly-rate="(value) => (form.hourly_rate = value)"
          @update:monthly-rate="(value) => (form.monthly_rate = value)"
          @update:project-interested="
            (value) => (form.project_interested = value)
          "
          @update:team-size="(value) => (form.team_size = value)"
          @update:want-to-full-time="(value) => (form.ramp_up = value)"
        />
        <PSeparator />
        <ApplicationFormSectionLinks
          :cv-file="
            (initialData.attachments ?? []).find(
              ({ type }) => type === 'cv_file'
            )
          "
          :designer-link="initialData.social_links?.designer_link"
          :github-link="initialData.social_links?.github_link"
          :kaggle-link="initialData.social_links?.kaggle_link"
          :linkedin-link="initialData.social_links?.linkedin_link"
          :other-file="
            (initialData.attachments ?? []).find(
              ({ type }) => type === 'other_file'
            )
          "
          :other-link="initialData.social_links?.other_link"
          :required-fields="requiredFields"
          :upload-progress="uploadProgress"
          @update:cv-file="
            (value) =>
              (form = {
                ...form,
                attachments: { ...form.attachments, cv_file: value },
              })
          "
          @update:designer-link="(value) => (form.designer_link = value)"
          @update:github-link="(value) => (form.github_link = value)"
          @update:kaggle-link="(value) => (form.kaggle_link = value)"
          @update:linkedin-link="(value) => (form.linkedin_link = value)"
          @update:other-file="
            (value) =>
              (form = {
                ...form,
                attachments: { ...form.attachments, other_file: value },
              })
          "
          @update:other-link="(value) => (form.other_link = value)"
        />
      </form>
    </BaseConfirmationCard>
  </PDialog>
</template>
