<script>
/**
 * @typedef { import('vue').Ref } Ref
 */

/**
 * Represents the links and attachments of an applicant.
 *
 * @typedef {Object} ApplicationAdditionalInformation
 * @property {?(string|('Full-time'|'Part-time'|'As needed')[])} commitmentType - Commitment type of the applicant.
 * @property {?string} currency - Currency of the applicant.
 * @property {?number} hourlyRate - Hourly rate of the applicant.
 * @property {?number} monthlyRate - Monthly rate of the applicant.
 * @property {?(string|('Green field'|'Newly started'|'Platform improvement')[])} projectInterested - Projects interested in of the applicant.
 * @property {?(string|('Large team'|'Small team'|'Team of one'))} teamSize - Team size of the applicant.
 * @property {?boolean} wantToFullTime - Indicates if the applicant wants to work full-time.
 */
export default {
  name: 'ApplicationFormAdditionalInformation',
};
</script>
<script setup>
import {
  BaseBadge,
  BaseExpandableCard,
  BaseInputDropdown,
  BaseInputField,
} from '@/components/Base';
import { computed, ref, watch } from 'vue';
import { answers } from '@/constants/filters';

const props = defineProps({
  commitmentType: {
    type: String,
    required: false,
    default() {
      return '';
    },
  },
  currency: {
    type: String,
    required: false,
    default() {
      return 'EUR';
    },
  },
  hourlyRate: {
    type: Number,
    required: false,
    default: undefined,
  },
  monthlyRate: {
    type: Number,
    required: false,
    default: undefined,
  },
  projectInterested: {
    type: String,
    required: false,
    default() {
      return '';
    },
  },
  requiredFields: {
    type: Array,
    required: false,
    default() {
      return [];
    },
  },
  teamSize: {
    type: String,
    required: false,
    default() {
      return '';
    },
  },
  wantToFullTime: {
    type: Boolean,
    required: false,
    default() {
      return false;
    },
  },
});

const emit = defineEmits([
  'update:commitment-type',
  'update:currency',
  'update:hourly-rate',
  'update:monthly-rate',
  'update:project-interested',
  'update:team-size',
  'update:want-to-full-time',
]);

/** @type {Ref<ApplicationAdditionalInformation>} */
const form = ref({});
/** @type {Ref<boolean>} */
const expanded = ref(false);

const availabilityOption =
  'Prefers to start part-time, then ramp up to full-time';

/** @type {{ value: string; label: string; }[]} */
const availabilityOptions = [
  ...answers.commitment_type,
  availabilityOption,
].map((item) => {
  return {
    value: item,
    label: item,
  };
});

const availability = computed({
  /** @returns {string[]} */
  get() {
    return [
      ...(form.value.commitmentType ?? []),
      ...(form.value.wantToFullTime ? [availabilityOption] : []),
    ];
  },
  /** @param {string[]} value */
  set(value) {
    form.value.wantToFullTime = (value ?? []).includes(availabilityOption);
    form.value.ramp_up = (value ?? []).includes(availabilityOption);
    form.value.commitmentType = (value ?? []).filter(
      (item) => item !== availabilityOption
    );
  },
});
/** returns {boolean} */
const hasRequiredField = computed(() => {
  return [
    'Hourly rate',
    'Full-time monthly rate',
    'Current availability',
    'Preferred team size',
    'Projects interested in',
  ].some((item) => props.requiredFields.includes(item));
});

watch(
  () => ({
    commitmentType: props.commitmentType,
    currency: props.currency,
    hourlyRate: props.hourlyRate,
    monthlyRate: props.monthlyRate,
    projectInterested: props.projectInterested,
    teamSize: props.teamSize,
    wantToFullTime: props.wantToFullTime,
  }),
  /** @param {ApplicationAdditionalInformation} newProps */
  (newProps) => {
    form.value = {
      commitmentType: newProps.commitmentType
        ? JSON.parse(newProps.commitmentType)
        : null,
      currency: newProps.currency,
      hourlyRate: newProps.hourlyRate,
      monthlyRate: newProps.monthlyRate,
      projectInterested: newProps.projectInterested
        ? JSON.parse(newProps.projectInterested)
        : null,
      teamSize: newProps.teamSize ? JSON.parse(newProps.teamSize) : null,
      wantToFullTime: newProps.wantToFullTime,
    };
    expanded.value = Boolean(
      newProps.commitmentType ||
        newProps.hourlyRate ||
        newProps.monthlyRate ||
        newProps.projectInterested ||
        newProps.teamSize ||
        newProps.wantToFullTime
    );
  },
  { deep: true, immediate: true }
);
watch(
  () => form.value,
  /** @param {ApplicationAdditionalInformation} newForm */
  (newForm) => {
    emit(
      'update:commitment-type',
      newForm.commitmentType ? JSON.stringify(newForm.commitmentType) : null
    );
    emit('update:currency', newForm.currency);
    emit('update:hourly-rate', newForm.hourlyRate);
    emit('update:monthly-rate', newForm.monthlyRate);
    emit(
      'update:project-interested',
      newForm.projectInterested
        ? JSON.stringify(newForm.projectInterested)
        : null
    );
    emit(
      'update:team-size',
      newForm.teamSize ? JSON.stringify(newForm.teamSize) : null
    );
    emit('update:want-to-full-time', newForm.wantToFullTime);
  },
  { deep: true, immediate: true }
);
defineExpose({
  hasRequiredField,
  form,
  availability,
});
</script>
<template>
  <KeepAlive>
    <component
      :is="!hasRequiredField ? BaseExpandableCard : 'div'"
      v-model="expanded"
      :class="{ 'border-none !shadow-none !px-0 !py-0': !hasRequiredField }"
    >
      <template v-if="hasRequiredField">
        <div
          class="text-body-md font-semibold text-proxify-gray-900 my-2"
          data-testid="static-header"
        >
          Additional Information
        </div>
      </template>
      <template
        v-if="!hasRequiredField"
        #header
      >
        <div
          class="flex flex-col gap-2"
          data-testid="expanded-header"
        >
          <div class="flex gap-2 items-center">
            <div class="text-body-md font-semibold text-proxify-gray-900">
              Additional Information
            </div>
            <BaseBadge class="!text-body-xs !font-medium !px-2 !py-0.5">
              Optional
            </BaseBadge>
          </div>
          <Transition>
            <div
              v-if="!expanded"
              class="text-proxify-gray-600 font-normal text-body-sm"
            >
              Add rates, availability, preferred team size and projects.
            </div>
          </Transition>
        </div>
      </template>
      <div class="flex flex-col gap-1">
        <div class="my-2 grid gap-4 grid-cols-2">
          <div>
            <div class="text-body-sm font-medium text-proxify-gray-700 mb-2">
              <span>Hourly rate</span>
              <span v-if="requiredFields.includes('Hourly rate')">*</span>
            </div>
            <BaseInputField
              v-model="form.hourlyRate"
              v-model:trailing-model-value="form.currency"
              name="Hourly rate"
              :leading-text="form.currency === 'USD' ? '$' : '€'"
              type="number"
              min="0"
              placeholder="0,00"
              trailing-dropdown
              :trailing-border="false"
              :trailing-menu-width="80"
              :trailing-menu-offset="[128, 6]"
              trailing-name="Currency"
              validate-on-value-update
            />
          </div>
          <div>
            <div class="text-body-sm font-medium text-proxify-gray-700 mb-2">
              <span>Full-time monthly rate</span>
              <span v-if="requiredFields.includes('Full-time monthly rate')">
                *
              </span>
            </div>
            <BaseInputField
              v-model="form.monthlyRate"
              v-model:trailing-model-value="form.currency"
              name="Full-time monthly rate"
              :leading-text="form.currency === 'USD' ? '$' : '€'"
              type="number"
              min="0"
              placeholder="0,00"
              trailing-dropdown
              :trailing-border="false"
              :trailing-menu-width="80"
              :trailing-menu-offset="[128, 6]"
              trailing-name="Currency"
              validate-on-value-update
            />
          </div>
        </div>
        <div class="my-2">
          <div class="text-body-sm font-medium text-proxify-gray-700 mb-2">
            <span>Current availability</span>
            <span v-if="requiredFields.includes('Current availability')">
              *
            </span>
          </div>
          <BaseInputDropdown
            v-model="availability"
            name="Current availability"
            type="tags"
            map-options
            :options="availabilityOptions"
            option-label="label"
            option-value="value"
            emit-value
            class="w-full"
          />
        </div>
        <div class="my-2">
          <div class="text-body-sm font-medium text-proxify-gray-700 mb-2">
            <span>Preferred team size</span>
            <span v-if="requiredFields.includes('Preferred team size')">*</span>
          </div>
          <BaseInputDropdown
            v-model="form.teamSize"
            name="Preferred team size"
            type="tags"
            map-options
            option-label="label"
            option-value="value"
            :options="
              answers.team_size.map((item) => {
                return {
                  value: item,
                  label: item,
                };
              })
            "
            emit-value
            class="w-full"
          />
        </div>
        <div class="my-2">
          <div class="text-body-sm font-medium text-proxify-gray-700 mb-2">
            <span>Projects interested in</span>
            <span v-if="requiredFields.includes('Projects interested in')">
              *
            </span>
          </div>
          <BaseInputDropdown
            v-model="form.projectInterested"
            name="Projects interested in"
            type="tags"
            map-options
            option-label="label"
            option-value="value"
            :options="
              answers.project_interested.map((item) => {
                return {
                  value: item,
                  label: item,
                };
              })
            "
            emit-value
            class="w-full"
          />
        </div>
      </div>
    </component>
  </KeepAlive>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.4s;
}

.v-enter,
.v-leave-to {
  opacity: 0;
}
</style>
