<template>
  <div class="applicant-container">
    <div
      class="flex flex-row justify-between items-center px-8 pb-4"
      :class="!isNotificationBarActive && !isTodosMenuActive && 'lg:pt-5'"
    >
      <div
        v-if="readOnly"
        class="title"
      >
        {{ data.full_name }}
      </div>
      <div
        v-else
        class="title"
      >
        Details
      </div>
      <div
        v-if="readOnly"
        class="flex flex-row"
      >
        <StarRating
          :value="data.average_rating"
          :spacing="0.25"
          :height="1"
        />
      </div>
      <div
        v-else
        class="text-body-sm font-semibold text-proxify-primary-700 cursor-pointer"
        @click="openApplicationFormDialog"
      >
        Edit
      </div>
    </div>
    <div
      ref="informationCardsWrapper"
      class="overflow-x-hidden ats-scrollbar-persistent px-8 mt-1 flex flex-col grow"
    >
      <div
        v-if="isLoading"
        class="relative py-2 flex flex-col grow"
      >
        <AppLoader
          class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          size="0.50rem"
        />
      </div>
      <div
        v-else
        ref="informationCards"
        class="gap-4"
        :class="
          isNotificationBarActive || isTodosMenuActive
            ? 'columns-1'
            : 'sm:columns-2 md:columns-1 lg:columns-2'
        "
      >
        <ApplicantCard
          v-if="data?.stage?.name"
          ref="stage"
          :title="data.is_rejected ? 'Rejection Information' : 'Status'"
        >
          <TextData
            v-if="readOnly"
            title="Status"
            :value="data?.stage?.name"
            value-class="text-proxify-black mb-0"
          />
          <div
            v-else-if="data?.is_rejected"
            class="flex flex-col gap-2"
          >
            <TextData
              title="Rejected stage"
              :value="data?.stage?.name"
            />
            <div>
              <TextData title="Reason">
                <template #append>
                  <div
                    v-if="data?.rejection?.reasons?.length"
                    data-testid="main-rejection-reason"
                  >
                    <span>
                      {{
                        data.rejection.reasons.find((reason) => reason.is_main)
                          ?.name
                      }}
                    </span>
                    <span v-if="data.rejection.reasons.length > 1">
                      , {{ data.rejection.reasons.length - 1 }}
                    </span>
                    <BaseIcon
                      name="info-circle"
                      size="14px"
                      class="ml-1 text-proxify-gray-500"
                    />
                    <BaseTooltip
                      tooltip-class="border border-proxify-gray-200 gap-[12px]"
                      max-width="216px"
                    >
                      <div class="rejection-popup-title">
                        <span v-if="data.rejection.reasons.length === 1">
                          {{ data.rejection.reasons[0].name }}
                        </span>
                        <span v-else>Rejection Reasons</span>
                      </div>
                      <div
                        class="rejection-popup-text"
                        data-testid="rejection-popup-text"
                      >
                        <p
                          v-for="(reason, index) in [
                            ...data.rejection.reasons,
                          ].sort((a, b) => b.is_main - a.is_main)"
                          :key="`${index}_${reason.name}`"
                        >
                          <span
                            v-if="
                              data.rejection.reasons.length > 1 &&
                              reason.name !== 'Other'
                            "
                            class="font-semibold"
                          >
                            {{ reason.name }}:
                          </span>
                          {{ reason.description }}
                        </p>
                        <p v-if="data.rejection.details">
                          <span class="font-semibold">
                            {{
                              data.rejection.reasons.some(
                                (reason) => reason.name === 'Other'
                              )
                                ? 'Other:'
                                : 'Detail added:'
                            }}
                          </span>
                          {{ data.rejection.details }}
                        </p>
                      </div>
                    </BaseTooltip>
                  </div>
                </template>
              </TextData>
            </div>
            <div class="flex flex-col gap-1 mt-1">
              <BaseBadge
                v-if="data?.rejection?.type === 'Auto-Rejected'"
                class="w-fit"
              >
                Auto-Rejected
              </BaseBadge>
              <div
                v-else
                class="flex items-center"
              >
                by
                <AppAvatar
                  v-bind="data?.rejection?.rejected_by"
                  :show-name="true"
                  class="ml-1"
                />
              </div>
              <div class="text-proxify-gray-600 font-normal">
                on
                {{
                  moment(data?.rejection?.created_at).format(
                    'DD MMMM YYYY, HH:mm'
                  )
                }}
              </div>
            </div>
          </div>
          <BaseButtonDropdown
            v-else
            :options="menuOptions.stage"
            map-options
            :model-value="data?.stage"
            class=""
            @update:model-value="handleChangeStage"
          >
            <template #activator>
              <BaseBadge
                color="transparent"
                class="!text-proxify-primary-700 !font-semibold !p-0 cursor-pointer font-body-sm h-[unset]"
              >
                <template
                  v-if="data?.auto_transition"
                  #prepend
                >
                  <div>
                    <BaseIcon
                      name="magic-wand01"
                      class="text-proxify-primary-600"
                      size="14px"
                    />
                    <BaseTooltip max-width="216px">
                      <div class="text-body-sm">
                        <div class="font-medium">
                          <BaseIcon
                            name="magic-wand01"
                            class="mr-1 mb-1 text-proxify-warning-500"
                            size="14px"
                          />
                          Auto transition
                        </div>
                        <div
                          class="my-2 font-normal text-proxify-gray whitespace-normal"
                        >
                          This candidate was automatically moved from
                          <span class="font-semibold">
                            {{
                              getStageName(data?.auto_transition?.from_stage_id)
                            }}
                          </span>
                          to
                          <span class="font-semibold">
                            {{ data?.stage?.name }}
                          </span>
                        </div>
                        <div class="text-proxify-gray-600 font-normal">
                          {{
                            moment(data?.auto_transition?.moved_at).format(
                              'On DD MMM YYYY'
                            )
                          }}
                        </div>
                      </div>
                    </BaseTooltip>
                  </div>
                </template>
                {{ data?.stage?.name }}
                <template #append>
                  <BaseIcon
                    name="chevron-down"
                    color="primary"
                    size="20px"
                  />
                </template>
              </BaseBadge>
            </template>
          </BaseButtonDropdown>
        </ApplicantCard>
        <ApplicantCardDuplications
          v-if="data?.duplications?.length"
          :duplications="data?.duplications"
          :is-duplicate="Boolean(data?.main_application_id)"
        />
        <ApplicantCard
          ref="recruiter"
          title="Recruiter"
        >
          <div class="flex flex-col gap-y-4">
            <TextData
              v-if="readOnly"
              :value="data?.assignees?.recruiter?.name"
              title="Recruiter"
            />
            <BaseButtonDropdown
              v-else
              :options="menuOptions.recruiter"
              map-options
              :model-value="data?.assignees?.recruiter"
              @update:model-value="handleChangeRecruiter"
            >
              <template #activator>
                <BaseBadge
                  color="transparent"
                  class="!text-proxify-gray-700 !font-semibold !p-0 cursor-pointer font-body-sm h-[unset]"
                >
                  {{ data?.assignees?.recruiter?.name ?? 'Unassigned' }}
                  <template #append>
                    <BaseIcon
                      name="chevron-down"
                      color="text-proxify-gray-600"
                      size="20px"
                    />
                  </template>
                </BaseBadge>
              </template>
            </BaseButtonDropdown>
          </div>
        </ApplicantCard>
        <ApplicantCard
          v-if="data?.recordings?.length"
          :title="recordingsTitle"
          custom-class="px-4 pt-4"
        >
          <AudioRecordings :recordings="data?.recordings" />
        </ApplicantCard>
        <ApplicantCard
          v-if="data?.source"
          ref="sourcer"
          title="Source"
          max-height="none"
          class="relative"
        >
          <template #title>
            <div class="flex justify-between">
              <div class="text-body-md text-proxify-gray-900 font-bold">
                Source
              </div>
              <SourceLabel
                :referrer-email="data?.referrer_email"
                :source="data?.source"
                :traffic-source="data?.traffic_source"
              />
            </div>
          </template>
          <div class="flex flex-col gap-4">
            <SourceData
              v-if="
                (data?.source === 'careers' && data?.traffic_source) ||
                (data?.source === 'referred' && data?.referrer_email)
              "
              :referrer-email="data?.referrer_email"
              :source="data?.source"
              :traffic-source="data?.traffic_source"
            />
            <TextData
              v-if="readOnly && data.assignees.sourcer"
              :value="data?.assignees?.sourcer?.name"
              title="Sourcer"
            />
            <div
              v-if="
                (data?.source === 'careers' || data?.source === 'sourced') &&
                !readOnly
              "
            >
              <BaseButtonDropdown
                :options="menuOptions.sourcer"
                map-options
                :model-value="data?.assignees?.sourcer"
                class="flex"
                @update:model-value="handleChangeSourcer"
              >
                <template #activator>
                  <span
                    v-if="data?.assignees?.sourcer?.name"
                    class="!text-proxify-gray-600 mr-1 text-body-sm font-normal"
                  >
                    by
                  </span>
                  <BaseBadge
                    color="transparent"
                    class="!text-proxify-gray-700 !font-semibold !p-0 cursor-pointer font-body-sm h-[unset]"
                  >
                    {{ data?.assignees?.sourcer?.name ?? 'Select Sourcer' }}
                    <template #append>
                      <BaseIcon
                        name="chevron-down"
                        color="text-proxify-gray-600"
                        size="20px"
                      />
                    </template>
                  </BaseBadge>
                </template>
              </BaseButtonDropdown>
            </div>
          </div>
        </ApplicantCard>
        <ApplicantCard
          v-if="data?.quiz_results?.length"
          title="Quizzes"
        >
          <PercentageData
            v-for="item in data.quiz_results"
            :key="item.quiz_id"
            :title="item.quiz?.name"
            :value="item.score"
          />
        </ApplicantCard>
        <ApplicantCard
          v-if="
            data?.monthly_rate ||
            data?.hourly_rate ||
            data?.monthly_rate_updated ||
            data?.hourly_rate_updated ||
            data?.confirmed_hourly_rate ||
            data?.confirmed_monthly_rate
          "
          title="Rate"
        >
          <div class="flex flex-col gap-2">
            <TextData
              v-if="data?.confirmed_hourly_rate"
              title="Confirmed Hourly Rate"
              :value="
                getCurrencySymbol(data.confirmed_currency) +
                data?.confirmed_hourly_rate
              "
              verified
            />
            <TextData
              v-if="data?.confirmed_monthly_rate"
              title="Confirmed Monthly Rate"
              :value="
                getCurrencySymbol(data.confirmed_currency) +
                data?.confirmed_monthly_rate
              "
              verified
            />
            <TextData
              v-if="data?.monthly_rate || data?.monthly_rate_updated"
              title="Expected Monthly Rate"
              :value="
                getCurrencySymbol(data.currency) +
                (data?.monthly_rate_updated ?? data?.monthly_rate)
              "
            />
            <ApplicantRateBenchmark
              v-if="
                (data?.hourly_rate_updated || data?.hourly_rate) &&
                hourlyRates.length
              "
              :key="data?.id"
              :hourly-rate="data?.hourly_rate_updated ?? data?.hourly_rate"
              :currency="getCurrencySymbol(data.currency)"
              :rates="hourlyRates"
            ></ApplicantRateBenchmark>
            <TextData
              v-if="
                data?.commitment_type &&
                JSON.parse(data.commitment_type)?.length > 0
              "
              title="Commitment"
              :value="JSON.parse(data.commitment_type).join(',\n')"
            />
          </div>
        </ApplicantCard>
        <ApplicantCardSkills
          v-if="data?.application_skills?.length"
          :key="data?.id"
          :skills="data?.application_skills"
          :key-skill-rates="keySkillRates"
        />
        <ApplicantCard
          v-if="
            data?.competency ||
            data?.commercial_exp_months ||
            data?.freelance_exp_months ||
            data?.english_level > 0
          "
          title="Experience"
        >
          <div class="flex flex-col gap-2">
            <ApplicantExperienceBenchmark
              v-if="data?.commercial_exp_months && experienceRates.length"
              :key="data?.id"
              :rates="experienceRates"
              :experience="data?.commercial_exp_months"
            ></ApplicantExperienceBenchmark>
            <TextData
              v-else-if="data?.commercial_exp_months"
              title="Commercial exp"
              :value="data?.commercial_experience.replace(' ', '')"
            />
            <TextData
              v-if="data?.freelance_exp_months"
              title="Freelance exp"
              :value="data?.freelance_experience.replace(' ', '')"
            />
            <TextData
              v-if="data?.competency"
              title="Competency"
              :value="data?.competency"
              value-class="truncate text-proxify-black"
            />
            <TextData
              v-if="data?.english_level > 0"
              title="English level"
              :value="englishLevel[data?.english_level - 1]"
              value-class="truncate text-proxify-black"
            />
          </div>
        </ApplicantCard>
        <ApplicantCard
          v-if="
            data?.social_links?.github_link ||
            data?.social_links?.linkedin_link ||
            data?.social_links?.designer_link ||
            data?.social_links?.kaggle_link ||
            data?.social_links?.other_link ||
            data?.attachments?.length
          "
          title="Links & attachments"
        >
          <div class="flex flex-col gap-2">
            <LinkData
              v-if="data?.social_links?.github_link"
              :url="data?.social_links?.github_link"
              value="Github"
            />
            <LinkData
              v-if="data?.social_links?.linkedin_link"
              :url="data?.social_links?.linkedin_link"
              value="Linkedin"
            />
            <LinkData
              v-if="data?.social_links?.designer_link"
              :url="data?.social_links?.designer_link"
              value="Designers (Behance/Dribble)"
            />
            <LinkData
              v-if="data?.social_links?.kaggle_link"
              :url="data?.social_links?.kaggle_link"
              value="Kaggle"
            />
            <LinkData
              v-if="data?.social_links?.other_link"
              :url="data?.social_links?.other_link"
              :value="otherLinkValue"
              value-class="truncate"
            />
            <LinkData
              v-for="item in data?.attachments"
              :key="item.id"
              :url="item.download_url"
              icon="download02"
              :value="`Download ${item.original_name}`"
              @click.prevent.stop="downloadFile(item.download_url)"
            />
          </div>
        </ApplicantCard>
        <ApplicantCard
          v-if="
            data?.email || data?.phone || location || data?.confirmed_country
          "
          ref="contactDetails"
          title="Contact details"
        >
          <div class="flex flex-col gap-2">
            <EmailData
              v-if="data.email"
              title="Email"
              :value="data.email"
            />
            <TextData
              v-if="data?.phone"
              title="Phone"
              :value="data?.phone"
            />
            <TextData
              v-if="data?.confirmed_country"
              ref="countryData"
              title="Tax residence"
              :value="
                countries.find((item) => item.code === data.confirmed_country)
                  ?.name
              "
              verified
              :value-class="{
                '!text-ats-red cursor-help': Boolean(isRestricted),
              }"
            />
            <div>
              <TextData
                v-if="location"
                ref="countryData"
                title="Country"
                :value="location"
                :value-class="{
                  '!text-ats-red cursor-help': Boolean(isRestricted),
                }"
              />
              <BaseTooltip v-if="Boolean(isRestricted)">
                <div>
                  <div class="text-heading-3">Restricted Country</div>
                  <div class="mt-4 font-medium leading-[150%]">
                    The candidate lives in
                    <span class="font-bold">{{ data.country }}</span>
                    , which is currently restricted in our system. We’re
                    probably not able to work with this candidate.
                  </div>
                </div>
              </BaseTooltip>
            </div>
            <TextData
              title="Planning to relocate"
              :value="Boolean(data?.relocate) ? 'Yes' : 'No'"
            />
          </div>
        </ApplicantCard>
        <ApplicantCard title="Work expectations">
          <div class="flex flex-col gap-2">
            <TextData
              v-if="
                data?.project_interested &&
                JSON.parse(data.project_interested).length > 0
              "
              title="Projects"
              :value="JSON.parse(data.project_interested).join(',\n')"
            />
            <TextData
              v-if="data?.team_size && JSON.parse(data.team_size).length > 0"
              title="Team size"
              :value="JSON.parse(data.team_size).join(',\n')"
            />
            <TextData
              title="Ramp up to full"
              :value="Boolean(data?.want_to_fulltime) ? 'Yes' : 'No'"
            />
            <TextData
              title="Start full-time"
              :value="Boolean(data?.first_start_fulltime) ? 'Yes' : 'No'"
            />
            <TextData
              title="Start part-time"
              :value="Boolean(data?.first_start_parttime) ? 'Yes' : 'No'"
            />
          </div>
        </ApplicantCard>
        <ApplicantCard
          title="Activity"
          class="break-after-avoid break-before-avoid"
        >
          <div class="flex flex-col gap-2">
            <ActivityData
              v-if="data?.applied_at"
              title="Applied"
              :value="getTimeDifference(data.applied_at)"
              :date="data?.applied_at"
              :value-class="
                calculateRelativeClass(data.applied_at, data.stage?.days_due)
              "
              data-testid="applied-at"
            />
            <ActivityData
              v-if="data?.last_activity"
              title="Last activity"
              :value="getTimeDifference(data.last_activity)"
              :date="data?.last_activity"
              :value-class="
                calculateRelativeClass(data.last_activity, data.stage?.days_due)
              "
            />
          </div>
          <!-- create an input -->
        </ApplicantCard>
      </div>
    </div>
  </div>
</template>

<script>
import ActivityData from '@/components/Elements/Scaffold/ActivityData';
import ApplicantCard from '@/components/Elements/Applicant/ApplicantCard.vue';
import ApplicantCardDuplications from '@/components/Elements/Applicant/ApplicantCardDuplications.vue';
import ApplicantCardSkills from '@/components/Elements/Applicant/ApplicantCardSkills.vue';
import EmailData from '@/components/Elements/Scaffold/EmailData';
import LinkData from '@/components/Elements/Scaffold/LinkData';
import PercentageData from '@/components/Elements/Scaffold/PercentageData';
import SourceData from '@/components/Elements/Applicant/ApplicantInformationSourceData';
import SourceLabel from '@/components/Elements/Applicant/ApplicantInformationSourceLabel';
import TextData from '@/components/Elements/Scaffold/TextData';
import snackbarMessagesMixin from '@/mixins/snackbarMessagesMixin';
import StarRating from '@/components/Icons/IconStarRating.vue';
import api from '@/api';
import moment from 'moment';
import { currencyUtils, dateTimeUtils, ratingUtils } from '@/utils';
import { computed, ref } from 'vue';
import { mapState, useStore } from 'vuex';
import AudioRecordings from '@/components/Elements/Applicant/ApplicantInformationAudioRecordings.vue';
import BaseIcon from '@/components/Base/BaseIcon.vue';
import { BaseButtonDropdown, BaseBadge, BaseTooltip } from '@/components/Base';
import countries from '@/constants/countries.json';
import ApplicantRateBenchmark from '@/components/Elements/Applicant/ApplicantRateBenchmark.vue';
import ApplicantExperienceBenchmark from '@/components/Elements/Applicant/ApplicantExperienceBenchmark.vue';

export default {
  components: {
    ApplicantExperienceBenchmark,
    ApplicantRateBenchmark,
    ActivityData,
    ApplicantCard,
    ApplicantCardDuplications,
    ApplicantCardSkills,
    EmailData,
    LinkData,
    PercentageData,
    SourceData,
    SourceLabel,
    TextData,
    StarRating,
    AudioRecordings,
    BaseIcon,
    BaseTooltip,
    BaseButtonDropdown,
    BaseBadge,
  },
  mixins: [snackbarMessagesMixin],
  props: {
    data: {
      type: Object,
      required: true,
    },
    handleChangeStage: {
      type: Function,
      required: false,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
    menuOptions: {
      type: Object,
      required: false,
      default: () => {
        return {
          stage: [],
          recruiter: [],
          sourcer: [],
        };
      },
    },
    updateStage: {
      type: Function,
      required: false,
      default: () => {},
    },
    openApplicationFormDialog: {
      type: Function,
      required: false,
      default: () => {},
    },
    readOnly: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    const store = useStore();
    const contactDetails = ref(null);
    const informationCardsWrapper = ref(null);
    const isNotificationBarActive = computed(
      () => store.state.settings.isNotificationBarActive
    );
    const isTodosMenuActive = computed(
      () => store.state.todos.isTodosMenuActive
    );
    return {
      contactDetails,
      informationCardsWrapper,
      calculateRelativeClass: dateTimeUtils.calculateRelativeClass,
      getTimeDifference: dateTimeUtils.getTimeDifference,
      moment,
      getCurrencySymbol: currencyUtils.getCurrencySymbol,
      isNotificationBarActive,
      isTodosMenuActive,
    };
  },
  data() {
    return {
      loading: false,
      isRestricted: false,
      newStage: {},
      showPopupTimeout: null,
      isPopupActive: false,
    };
  },
  computed: {
    ...mapState({
      countries: (state) => state.applicant.countries,
      stages: (state) => state.applicant.stages,
    }),
    applicationId() {
      return this.$route.params.id;
    },
    englishLevel() {
      return ['Beginner', 'Intermediate', 'Advanced', 'Fluent'];
    },
    otherLinkValue() {
      const { other_link } = this.data.social_links;
      try {
        const link = new URL(other_link);
        return link.hostname + link.pathname;
      } catch (error) {
        return '';
      }
    },
    location() {
      const { country, city } = this.data;
      return this.countryFlag + [country, city].filter(Boolean).join(', ');
    },
    countryFlag() {
      const country = countries.find(
        (item) => item.code === this.data.country_code
      );
      return `${country?.unicode}&nbsp;&nbsp;`;
    },
    recordingsTitle() {
      return `Recordings (${this.data?.recordings?.length})`;
    },
    experienceRates() {
      return ratingUtils.getExperienceRatesOfApplication(this.data);
    },
    hourlyRates() {
      return ratingUtils.getHourlyRatesOfApplication(this.data);
    },
    keySkillRates() {
      return ratingUtils.getKeySkillRatesForApplication(this.data);
    },
  },
  watch: {
    data: {
      handler(newValue) {
        if (this.countries.length && newValue) {
          this.isRestricted =
            this.countries.find(({ name }) => newValue.country === name)
              ?.risky ?? false;
        }
      },
      deep: true,
      immediate: true,
    },
    countries: {
      handler(newValue) {
        if (this.data && newValue) {
          this.isRestricted =
            newValue.find(({ name }) => this.data.country === name)?.risky ??
            false;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getStageName(stageId) {
      const stage = this.stages.find(({ id }) => id === stageId);
      return stage?.name ?? '';
    },
    async handleChangeRecruiter(recruiter) {
      this.loading = true;
      await api.applications.updateAssignees(this.applicationId, {
        sourcer_id: this.data.assignees?.sourcer?.id,
        recruiter_id: recruiter.id,
      });
      this.loading = false;
      this.showSuccessMessage('Recruiter changed successfully');
      this.$store.commit('applicant/setDataNeedsUpdate');
      this.$store.commit('applicant/setRecruiterChanged');
    },
    async handleChangeSourcer(sourcer) {
      this.loading = true;
      await api.applications.updateAssignees(this.applicationId, {
        recruiter_id: this.data.assignees?.recruiter?.id,
        sourcer_id: sourcer.id,
      });
      this.loading = false;
      this.$store.commit('applicant/setDataNeedsUpdate');
    },
    activatePopup() {
      this.showPopupTimeout = setTimeout(() => {
        this.isPopupActive = true;
      }, 500);
    },
    hidePopup() {
      clearTimeout(this.showPopupTimeout);
      this.isPopupActive = false;
    },
    selfInterviewStatusClass(status) {
      const map = {
        Skipped: 'text-ats-red',
        'Not needed': 'text-disabled-normal',
        'Record later': 'text-proxify-warning-500',
      };
      return map[status];
    },
    downloadFile(downloadUrl) {
      this.$store.dispatch('application/downloadAttachment', downloadUrl);
    },
  },
};
</script>

<style scoped>
.title {
  @apply text-body-lg
  font-semibold
  text-proxify-gray;
}

.applicant-container {
  @apply flex
  flex-col
  grow
  overflow-hidden;
}

.rejection-popup-title {
  @apply text-proxify-gray-700
  text-body-sm
  font-semibold;
}

.rejection-popup-text {
  @apply pt-4
  text-proxify-gray-700
  text-body-xs
  font-normal;
}
</style>
