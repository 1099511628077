<script setup>
import BaseBadge from '@/components/Base/BaseBadge.vue';
import BaseChartBar from '@/components/Base/BaseChartBar.vue';
import BaseTooltip from '@/components/Base/BaseTooltip.vue';
import BaseIcon from '@/components/Base/BaseIcon.vue';
import { computed, ref } from 'vue';
import { ratingUtils } from '@/utils';

const props = defineProps({
  skill: {
    type: Object,
    required: true,
  },
  rates: {
    type: Array,
    required: false,
    default: () => [],
  },
  showProficiency: {
    type: Boolean,
    default: true,
  },
  showExperience: {
    type: Boolean,
    default: true,
  },
  labelClass: {
    type: String,
    required: false,
    default: '',
  },
  showSupplyDemandLabel: {
    type: Boolean,
    default: true,
  },
});

const skillRef = ref(null);

const verdict = computed(() => {
  const { rates, skill } = props;
  return rates.length
    ? ratingUtils.calculateVerdict(skill.years * 12, props.rates)
    : false;
});

const color = computed(() => {
  const colors = {
    low: 'error',
    typical: 'gray',
    high: 'success',
  };

  return colors[verdict.value];
});

const proficiency = computed(() => {
  const levels = ['Aware', 'Experienced', 'Professional', 'Expert'];

  return levels[props.skill.proficiency_level - 1];
});

const supplyDemandContext = {
  'High demand': {
    icon: 'chevron-up-double',
    color: 'success',
    textClass: 'text-proxify-success-600 custom-width',
  },
  'Moderate demand': {
    icon: 'equal',
    color: 'gray',
    textClass: 'custom-width',
  },
  Oversupply: {
    icon: 'chevron-down-double',
    color: 'gray',
    textClass: 'custom-width',
  },
};
</script>

<template>
  <BaseBadge
    badge-class="flex bg-proxify-gray-100 skill-badge h-auto select-none"
  >
    <div
      class="flex items-center justify-between text-proxify-gray-600 text-body-sm flex-wrap gap-1 w-full"
      :class="labelClass"
    >
      <div class="flex flex-row items-center gap-1 max-w-full">
        <BaseBadge
          v-if="supplyDemandContext[skill.supply_demand_ratio?.label]"
          size="sm"
          :color="supplyDemandContext[skill.supply_demand_ratio?.label]?.color"
          :icon="supplyDemandContext[skill.supply_demand_ratio?.label]?.icon"
          icon-position="left"
          class="capitalize !text-body-sm !px-0 bg-transparent"
        >
          <BaseTooltip
            max-width="216px"
            :offset="[0, 10]"
          >
            <div class="flex flex-col gap-2">
              <div class="flex flex-row gap-1 items-center">
                <BaseIcon
                  :name="
                    supplyDemandContext[skill.supply_demand_ratio?.label]?.icon
                  "
                  size="16px"
                  :class="
                    supplyDemandContext[skill.supply_demand_ratio?.label]
                      ?.textClass
                  "
                  class="!w-4"
                />
                <span class="text-[14px]">
                  {{ skill.supply_demand_ratio?.label }}
                </span>
              </div>
              <span class="text-[12px] leading-[18px] font-normal">
                Current member to staffing request ratio:
                <span class="font-semibold">
                  {{ skill.supply_demand_ratio?.ratio }}:1
                </span>
              </span>
            </div>
          </BaseTooltip>
        </BaseBadge>
        <div
          class="flex truncate w-full"
          :class="
            supplyDemandContext[skill.supply_demand_ratio?.label]?.textClass
          "
        >
          <div class="flex-1 min-w-0">
            <div
              ref="skillRef"
              :class="{
                truncate: skillRef?.offsetWidth < skillRef?.scrollWidth,
              }"
            >
              {{ skill.skill?.name }}
            </div>
          </div>
          <div
            v-if="(!showProficiency || !showExperience) && skill.years"
            class="whitespace-nowrap ml-1"
          >
            - {{ skill.years }}y
          </div>
          <BaseTooltip
            v-if="(!showProficiency || !showExperience) && proficiency"
            :offset="[0, 6]"
          >
            {{
              [proficiency, `${skill.years}y`]
                .filter(
                  (_, i) =>
                    [
                      !showProficiency && proficiency,
                      !showExperience && skill.years,
                    ][i]
                )
                .join(' — ')
            }}
          </BaseTooltip>
        </div>
      </div>
      <div class="text-right flex-grow">
        <span v-if="showProficiency">
          <span v-if="skill.years">{{ skill.years }} y</span>
          <span v-if="skill.years && proficiency">-</span>
          <span v-if="proficiency">{{ proficiency }}</span>
        </span>
        <BaseBadge
          v-if="verdict"
          size="sm"
          :color="color"
          icon="info-circle"
          icon-position="right"
          class="capitalize !text-body-sm !font-medium !ml-2 !-mr-2 mix-blend-multiply"
          clickable
          :outline="false"
          data-testid="verdict-badge"
        >
          <span>
            {{ verdict }}
          </span>
          <BaseTooltip
            max-width="216px"
            :offset="[0, 10]"
          >
            <BaseChartBar
              :value="skill.years * 12"
              :rates="rates"
              unit="months"
              :description="`{verdict} experience in ${skill.skill.name} compared to similar profiles from the network`"
            ></BaseChartBar>
          </BaseTooltip>
        </BaseBadge>
      </div>
    </div>
  </BaseBadge>
</template>

<style scoped>
.custom-width {
  @apply w-[calc(100%-20px)];
}
</style>
