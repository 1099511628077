<script setup>
import { computed, ref, toRefs, watchEffect } from 'vue';
import { useBroadcastChannel } from '@vueuse/core';
import { useRouteParams } from '@vueuse/router';
import api from '@/api';
import { answers } from '@/constants/filters';
import { useStore } from 'vuex';

import {
  BaseCheckbox,
  BaseCountrySelector,
  BaseInputDropdown,
  BaseInputField,
  BaseSkillSelector,
} from '@/components/Base';

const props = defineProps({
  applicant: {
    type: Object,
    required: false,
    default() {
      return {};
    },
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  skills: {
    type: Array,
    required: false,
    default() {
      return [];
    },
  },
  requiresInitialData: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});
const { applicant, requiresInitialData, isLoading } = toRefs(props);

const applicationId = useRouteParams('id');
const store = useStore();
const { post, isClosed } = useBroadcastChannel({ name: 'data-needs-update' });

const form = ref({});
const isSaving = ref(false);

const updateField = async (field) => {
  if (isLoading.value) {
    return;
  }

  try {
    isSaving.value = true;
    const id = applicationId.value ?? applicant.value.id;
    let payload;

    const secondarySkills = (applicant.value.application_skills ?? [])
      .filter((item) => !item.main_skill)
      .map(({ years, proficiency_level, main_skill, skill }) => ({
        years,
        proficiency_level,
        main_skill,
        skill_id: skill.id,
      }));
    if (field.name === 'skills') {
      payload = [
        ...secondarySkills,
        ...field.value.map(
          ({
            main_skill,
            proficiency_level: proficiencyLevel,
            skill,
            years,
          }) => {
            return {
              main_skill,
              proficiency_level: proficiencyLevel?.value ?? proficiencyLevel,
              skill_id: skill.id,
              years,
            };
          }
        ),
      ];
      await api.applications.updateSkills(id, { skills: payload });
      store.commit('applicant/setDataNeedsUpdate');
      if (requiresInitialData.value && !isClosed.value) {
        post({ data: 'application', timestamp: Date.now() });
      }
      return;
    } else if (field.name === 'location') {
      payload = {
        ...applicant.value,
        ...{
          city: field.value?.city,
          country: field.value?.country,
          country_code: field.value?.country,
        },
      };
    } else {
      payload = {
        ...applicant.value,
        ...{
          [field.name]: field?.value,
          country: applicant.value.country_code,
        },
      };
    }
    await api.applications.putApplication(id, {
      ...payload,
      ...(applicant.value.source === 'sourced'
        ? { sourcer_id: applicant.value.assignees?.sourcer?.id ?? null }
        : {}),
    });
    store.commit('applicant/setDataNeedsUpdate');
    if (requiresInitialData.value && !isClosed.value) {
      post({ data: 'application', timestamp: Date.now() });
    }
  } catch (error) {
    console.error(error);
  } finally {
    isSaving.value = false;
  }
};

const noticePeriodOptions = [
  'Immediately',
  'In one week',
  'In two weeks',
  'In one month',
  'In more than 1 month',
];

const prefillFormData = (applicant) => {
  const {
    application_skills,
    city,
    commitment_type: commitmentType,
    competency,
    country,
    country_code,
    currency,
    relocate,
    hourly_rate_updated: hourlyRateUpdated,
    hourly_rate: hourlyRate,
    monthly_rate_updated: monthlyRateUpdated,
    monthly_rate: monthlyRate,
    work_type: workType,
    first_start_fulltime,
    first_start_parttime,
    want_to_fulltime,
  } = applicant;

  form.value = {
    application_skills,
    frontendSkill: null,
    backendSkill: null,
    primarySkill: null,
    city,
    commitment_type: commitmentType,
    competency,
    country,
    country_code,
    currency,
    first_start_fulltime,
    first_start_parttime,
    hourly_rate: hourlyRateUpdated ?? hourlyRate,
    monthly_rate: monthlyRateUpdated ?? monthlyRate,
    want_to_fulltime: Boolean(want_to_fulltime),
    relocate: Boolean(relocate),
    work_type: (commitmentType && JSON.parse(commitmentType)) ?? workType,
  };
};

watchEffect(() => {
  if (applicant.value) {
    prefillFormData(applicant.value);
  }
});

const setInitialSkill = (competency) => {
  // Step 1: Get application skills from initial data or an empty array if not available
  const applicationSkills = applicant.value.application_skills ?? [];

  // Step 2: Filter the skills to retain only those which are marked as 'main_skill'
  const mainSkills = applicationSkills.filter((item) => item.main_skill);

  // Step 3: Filter the skills further to include only those which belong to the specified competency
  const mainSkillsInCompetency = mainSkills.filter(({ skill }) => {
    // Check if this skill is included in the specified competency
    return props.skills
      .find((item) => item.id === skill.id)
      ?.skill_stack_names?.includes?.(competency);
  });

  // Step 4: Sort the skills in descending order of 'years' experience
  const sortedSkills = mainSkillsInCompetency.sort((a, b) => b.years - a.years);

  // Step 5: Pick the skill with highest 'years' experience (which is the first skill in sortedSkills)
  // If no such skill is found, 'skill' will be undefined
  const skill = sortedSkills[0];

  return {
    ...skill,
    ...(skill?.proficiency_level
      ? {
          proficiency_level: {
            value: skill?.proficiency_level,
            label: answers.proficiency_level?.[skill?.proficiency_level - 1],
          },
        }
      : {}),
  };
};

const frontendSkill = computed({
  get() {
    return form.value.frontendSkill ?? setInitialSkill('Frontend');
  },
  set(value) {
    form.value.frontendSkill = value;
  },
});

const backendSkill = computed({
  get() {
    return form.value.backendSkill ?? setInitialSkill('Backend');
  },
  set(value) {
    form.value.backendSkill = value;
  },
});

const primarySkill = computed({
  get() {
    const skill =
      form.value.primarySkill ??
      (applicant.value.application_skills ?? []).find(
        (item) => item.main_skill
      );
    return {
      ...skill,
      proficiency_level: {
        value: skill?.proficiency_level?.value ?? skill?.proficiency_level,
        label:
          skill?.proficiency_level?.label ??
          answers.proficiency_level?.[skill?.proficiency_level - 1],
      },
    };
  },
  set(value) {
    form.value.primarySkill = value;
  },
});

const isFullstack = computed(() =>
  (applicant.value?.competency ?? []).includes('Fullstack')
);

const availabilityOption =
  'Prefers to start part-time, then ramp up to full-time';
const availabilityOptions = computed(() => {
  return [...answers.commitment_type, availabilityOption].map((item) => {
    return {
      value: item,
      label: item,
    };
  });
});
const availability = computed({
  get() {
    return [
      ...(form.value.work_type ?? []),
      ...(form.value.want_to_fulltime ? [availabilityOption] : []),
    ];
  },
  set(value) {
    form.value.want_to_fulltime = (value ?? []).includes(availabilityOption);
    form.value.work_type = (value ?? []).filter(
      (item) => item !== availabilityOption
    );
  },
});
</script>

<template>
  <div
    v-if="applicant.id"
    class="font-inter"
  >
    <div v-if="readOnly">
      <div class="question-label mt-[16px]">Location and relocation plans</div>
      <div class="question-answer">
        {{ form.city }}, {{ form.country }}.
        <span v-if="form.relocate">Plans to relocate in &lt;6 months.</span>
      </div>
      <div class="question-label mt-[24px]">
        <span>Primary skill</span>
        <span v-if="isFullstack">(s)</span>
      </div>
      <template v-if="isFullstack">
        <div
          v-if="frontendSkill?.skill"
          class="question-answer"
        >
          {{ frontendSkill?.skill?.name }}, {{ frontendSkill?.years }} years,
          {{ frontendSkill?.proficiency_level?.label }}
        </div>
        <div
          v-if="backendSkill?.skill"
          class="question-answer"
        >
          {{ backendSkill?.skill?.name }}, {{ backendSkill?.years }} years,
          {{ backendSkill?.proficiency_level?.label }}
        </div>
      </template>
      <template v-else>
        <div
          v-if="primarySkill.skill"
          class="question-answer"
        >
          {{ primarySkill?.skill?.name }}, {{ primarySkill?.years }} years,
          {{ primarySkill?.proficiency_level?.label }}
        </div>
      </template>
      <div class="question-label mt-[24px]">Current availability</div>
      <div class="question-answer">
        {{ availability?.join(', ') }}
      </div>
      <div class="question-label mt-[24px]">Notice periods</div>
      <div class="question-answer mb-2">
        <span class="font-normal">Full-time:</span>
        {{ form.first_start_fulltime }}
      </div>
      <div class="question-answer">
        <span class="font-normal">Part-time:</span>
        {{ form.first_start_parttime }}
      </div>
      <div class="question-label mt-[24px]">Confirmed expected rates</div>
      <div class="question-answer mb-2">
        <span class="font-normal">Hourly rate:</span>
        {{ form.hourly_rate?.toLocaleString?.() }} {{ form.currency }}
      </div>
      <div class="question-answer">
        <span class="font-normal">Full-time monthly rate:</span>
        {{ form.monthly_rate?.toLocaleString?.() }} {{ form.currency }}
      </div>
    </div>

    <div v-else>
      <BaseCountrySelector
        :city="form.city"
        :country-code="form.country_code"
        input-width="220px"
        :disable="isSaving"
        @change="(value) => updateField({ name: 'location', value })"
      />
      <BaseCheckbox
        v-model="form.relocate"
        label="Plans to relocate in <6 months"
        name="Plans to relocate in <6 months"
        class="mt-3.5"
        :disable="isSaving"
        @update:model-value="
          (value) => updateField({ name: 'relocate', value })
        "
      />
      <template v-if="isFullstack">
        <div>
          <div class="text-body-md text-proxify-gray-700 font-normal mt-[24px]">
            Primary skills
          </div>
          <div class="text-body-sm text-proxify-gray-600 font-normal mt-2">
            The primary skill with the highest years of experience will be used
            for the Technical assessment and Interview.
          </div>
        </div>
        <BaseSkillSelector
          v-model="frontendSkill"
          :skills="
            skills.filter((skill) =>
              skill.skill_stack_names.includes('Frontend')
            )
          "
          title="Frontend skill"
          class="mt-2"
          :disable="isSaving"
          @update:model-value="
            (value) =>
              updateField({
                name: 'skills',
                value: [
                  ...(backendSkill?.skill?.id ? [backendSkill] : []),
                  value,
                ],
              })
          "
        />

        <BaseSkillSelector
          v-model="backendSkill"
          :skills="
            skills.filter((skill) =>
              skill.skill_stack_names.includes('Backend')
            )
          "
          title="Backend skill"
          class="mt-2"
          :disable="isSaving"
          @update:model-value="
            (value) =>
              updateField({
                name: 'skills',
                value: [
                  ...(frontendSkill?.skill?.id ? [frontendSkill] : []),
                  value,
                ],
              })
          "
        />
      </template>
      <template v-else>
        <BaseSkillSelector
          v-model="primarySkill"
          :skills="skills"
          title="Primary skill"
          class="mt-12"
          :disable="isSaving"
          @update:model-value="
            (value) =>
              updateField({
                name: 'skills',
                value: [value],
              })
          "
        />
      </template>
      <div class="text-body-sm font-medium text-proxify-gray-700 mt-[24px]">
        Current availability
      </div>
      <div class="flex gap-2 mt-2">
        <BaseInputDropdown
          v-model="availability"
          name="Current availability"
          type="tags"
          map-options
          :options="availabilityOptions"
          option-label="label"
          option-value="value"
          emit-value
          badge-size="lg"
          :disable="isSaving"
          @blur="
            () => {
              updateField({
                name: 'work_type',
                value: JSON.stringify(form.work_type),
              });
              updateField({
                name: 'ramp_up',
                value: form.want_to_fulltime ? 1 : 0,
              });
            }
          "
        />
      </div>
      <div class="flex gap-2 mt-[24px]">
        <div>
          <div class="text-body-sm font-medium text-proxify-gray-700 mb-2">
            Full-time notice period
          </div>
          <BaseInputDropdown
            v-model="form.first_start_fulltime"
            name="Full-time notice period"
            map-options
            :options="noticePeriodOptions"
            input-width="200px"
            :disable="isSaving"
            @update:model-value="
              (value) => updateField({ name: 'first_start_fulltime', value })
            "
          />
        </div>
        <div>
          <div class="text-body-sm font-medium text-proxify-gray-700 mb-2">
            Part-time notice period
          </div>
          <BaseInputDropdown
            v-model="form.first_start_parttime"
            name="Part-time notice period"
            map-options
            :options="noticePeriodOptions"
            input-width="200px"
            :disable="isSaving"
            @update:model-value="
              (value) => updateField({ name: 'first_start_parttime', value })
            "
          />
        </div>
      </div>
      <div class="flex gap-[8px] mt-[24px]">
        <div>
          <div class="text-body-sm font-medium text-proxify-gray-700 mb-2">
            Hourly rate
          </div>
          <BaseInputField
            v-model="form.hourly_rate"
            v-model:trailing-model-value="form.currency"
            name="Hourly rate"
            :leading-text="form.currency === 'USD' ? '$' : '€'"
            type="number"
            min="0"
            trailing-dropdown
            :trailing-border="false"
            input-width="200px"
            :trailing-menu-width="200"
            :trailing-menu-offset="[128, 6]"
            trailing-name="Currency"
            :disable="isSaving"
            @change="
              (event) =>
                updateField({
                  name: 'hourly_rate',
                  value: parseInt(event.target.value, 10),
                })
            "
            @update:trailing-model-value="
              (value) => updateField({ name: 'currency', value })
            "
          />
        </div>
        <div>
          <div class="text-body-sm font-medium text-proxify-gray-700 mb-2">
            Full-time monthly rate
          </div>
          <BaseInputField
            v-model="form.monthly_rate"
            v-model:trailing-model-value="form.currency"
            name="Full-time monthly rate"
            :leading-text="form.currency === 'USD' ? '$' : '€'"
            type="number"
            min="0"
            trailing-dropdown
            :trailing-border="false"
            input-width="200px"
            :trailing-menu-width="200"
            :trailing-menu-offset="[128, 6]"
            trailing-name="Currency"
            :disable="isSaving"
            @change="
              (event) =>
                updateField({
                  name: 'monthly_rate',
                  value: parseInt(event.target.value, 10),
                })
            "
            @update:trailing-model-value="
              (value) => updateField({ name: 'currency', value })
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.question-label {
  @apply mb-2
  text-body-md
  font-normal
  text-proxify-gray-700;
}

.question-answer,
.input-label {
  @apply text-body-sm
  font-medium
  text-proxify-gray-700;
}
</style>
